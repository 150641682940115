"use client";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { PollingMode, getClient } from "configcat-common";
import React, { Component } from "react";
import { LocalStorageCache } from "./Cache";
import ConfigCatContext from "./ConfigCatContext";
import { HttpConfigFetcher } from "./ConfigFetcher";
import CONFIGCAT_SDK_VERSION from "./Version";
var initializedClients = new Map();
var ConfigCatProvider = /** @class */ (function (_super) {
    __extends(ConfigCatProvider, _super);
    function ConfigCatProvider(props) {
        var _this = _super.call(this, props) || this;
        var client = !isServerContext()
            ? _this.initializeConfigCatClient()
            : new ConfigCatClientStub();
        _this.state = { client: client };
        return _this;
    }
    ConfigCatProvider.prototype.componentDidMount = function () {
        var _this = this;
        this.configChangedHandler = function (newConfig) { return _this.reactConfigChanged(newConfig); };
        this.state.client.waitForReady().then(function () {
            if (!_this.configChangedHandler) {
                // If the component was unmounted before client initialization finished, we have nothing left to do.
                return;
            }
            _this.state.client.on("configChanged", _this.configChangedHandler);
            _this.clientReady();
        });
    };
    ConfigCatProvider.prototype.componentWillUnmount = function () {
        var _a;
        this.state.client.off("configChanged", this.configChangedHandler);
        delete this.configChangedHandler;
        var refCount = ((_a = initializedClients.get(this.props.sdkKey)) !== null && _a !== void 0 ? _a : 1) - 1;
        initializedClients.set(this.props.sdkKey, refCount);
        if (refCount <= 0) {
            this.state.client.dispose();
            initializedClients.delete(this.props.sdkKey);
        }
    };
    ConfigCatProvider.prototype.initializeConfigCatClient = function () {
        var _a;
        var _b = this.props, pollingMode = _b.pollingMode, options = _b.options;
        var sdkKey = this.props.sdkKey;
        var configCatKernel = LocalStorageCache.setup({
            configFetcher: new HttpConfigFetcher(),
            sdkType: "ConfigCat-React",
            sdkVersion: CONFIGCAT_SDK_VERSION,
        });
        initializedClients.set(sdkKey, ((_a = initializedClients.get(sdkKey)) !== null && _a !== void 0 ? _a : 0) + 1);
        return getClient(sdkKey, pollingMode !== null && pollingMode !== void 0 ? pollingMode : PollingMode.AutoPoll, options, configCatKernel);
    };
    ConfigCatProvider.prototype.reactConfigChanged = function (_newConfig) {
        this.setState({ lastUpdated: new Date() });
    };
    ConfigCatProvider.prototype.clientReady = function () {
        this.setState({ lastUpdated: new Date() });
    };
    ConfigCatProvider.prototype.render = function () {
        return (React.createElement(ConfigCatContext.Provider, { value: this.state }, this.props.children));
    };
    return ConfigCatProvider;
}(Component));
function isServerContext() {
    return typeof XMLHttpRequest === "undefined";
}
function serverContextNotSupported() {
    return new Error("ConfigCat SDK functionality is not available in server context. "
        + "If you need it in both server and client contexts, please consider using the JS SSR SDK instead of React SDK.");
}
var ConfigCatClientStub = /** @class */ (function () {
    function ConfigCatClientStub() {
        this.isOffline = true;
    }
    ConfigCatClientStub.prototype.getValueAsync = function (_key, _defaultValue, _user) {
        throw serverContextNotSupported();
    };
    ConfigCatClientStub.prototype.getValueDetailsAsync = function (_key, _defaultValue, _user) {
        throw serverContextNotSupported();
    };
    ConfigCatClientStub.prototype.getAllKeysAsync = function () {
        throw serverContextNotSupported();
    };
    ConfigCatClientStub.prototype.getAllValuesAsync = function (_user) {
        throw serverContextNotSupported();
    };
    ConfigCatClientStub.prototype.getAllValueDetailsAsync = function (_user) {
        throw serverContextNotSupported();
    };
    ConfigCatClientStub.prototype.getKeyAndValueAsync = function (_variationId) {
        throw serverContextNotSupported();
    };
    ConfigCatClientStub.prototype.forceRefreshAsync = function () {
        throw serverContextNotSupported();
    };
    ConfigCatClientStub.prototype.waitForReady = function () {
        throw serverContextNotSupported();
    };
    ConfigCatClientStub.prototype.snapshot = function () {
        throw serverContextNotSupported();
    };
    ConfigCatClientStub.prototype.setDefaultUser = function (_defaultUser) {
        throw serverContextNotSupported();
    };
    ConfigCatClientStub.prototype.clearDefaultUser = function () {
        throw serverContextNotSupported();
    };
    ConfigCatClientStub.prototype.setOnline = function () {
        throw serverContextNotSupported();
    };
    ConfigCatClientStub.prototype.setOffline = function () {
        throw serverContextNotSupported();
    };
    ConfigCatClientStub.prototype.dispose = function () { };
    ConfigCatClientStub.prototype.addListener = function (_eventName, _listener) {
        throw serverContextNotSupported();
    };
    ConfigCatClientStub.prototype.on = function (_eventName, _listener) {
        throw serverContextNotSupported();
    };
    ConfigCatClientStub.prototype.once = function (_eventName, _listener) {
        throw serverContextNotSupported();
    };
    ConfigCatClientStub.prototype.removeListener = function (_eventName, _listener) {
        throw serverContextNotSupported();
    };
    ConfigCatClientStub.prototype.off = function (_eventName, _listener) {
        throw serverContextNotSupported();
    };
    ConfigCatClientStub.prototype.removeAllListeners = function (_eventName) {
        throw serverContextNotSupported();
    };
    ConfigCatClientStub.prototype.listeners = function (_eventName) {
        throw serverContextNotSupported();
    };
    ConfigCatClientStub.prototype.listenerCount = function (_eventName) {
        throw serverContextNotSupported();
    };
    ConfigCatClientStub.prototype.eventNames = function () {
        throw serverContextNotSupported();
    };
    return ConfigCatClientStub;
}());
export default ConfigCatProvider;
