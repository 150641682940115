import Icons from "../Icons";

export const infoWindowContent = (selectedPlace) => {
  return (
    <div className="">
      <h3 className="text-gray-900 text-lg font-bold">{selectedPlace.name}</h3>
      <address className="font-mono mb-2 text-gray-500">
        {selectedPlace.address.street}<br />
        {selectedPlace.address.city}, {selectedPlace.address.state} {selectedPlace.address.zipcode}
      </address>

      {selectedPlace.description && (
        <p className="my-4 text-gray-900 text-base max-w-lg">{selectedPlace.description}</p>
      )}

      {(() => {
        if (selectedPlace.openDate || selectedPlace.hours) {
          return (
            <>
              <div className="text-xs tracking-wider text-gray-700 border-b border-gray-100">Additional Info</div>

              {selectedPlace.openDate && (
                <p className="mb-1 text-xs"><span className="font-bold">Date open:</span> {selectedPlace.openDate}</p>
              )}

              {selectedPlace.hours && (
                <p className="mb-1 text-xs"><span className="font-bold">Hours:</span> {selectedPlace.hours}</p>
              )}
            </>
          )
        }
      })()}

      <div className="flex flex-col gap-2 md:gap-0 md:flex-row justify-between rounded-b-md items-center mt-2">
        <div className="social flex justify-start gap-2">
          {selectedPlace.social && (
            <>
              {selectedPlace.social.facebook && (
                <a
                  title="View on Facebook" target="_blank" href={selectedPlace.social.facebook}
                  onClick={ (e) => {
                    global.trackAnalyticsEvent('Social', 'Facebook', selectedPlace.name);
                  }}
                ><Icons width={20} fillColor="#2563EB" type="facebookSquare" className="transform hover:scale-125" /></a>
              )}
              {selectedPlace.social.instagram && (
                <a
                  title="View on Instagram" target="_blank" href={selectedPlace.social.instagram}
                  onClick={ (e) => {
                    global.trackAnalyticsEvent('Social', 'Instagram', selectedPlace.name);
                  }}
                ><Icons width={20} fillColor="text-blue-600" type="instagram" className="transform hover:scale-125" /></a>
              )}
              {selectedPlace.social.website && (
                <a
                  title="View website" target="_blank" href={selectedPlace.social.website}
                  onClick={ (e) => {
                    global.trackAnalyticsEvent('Social', 'Website', selectedPlace.name);
                  }}
                ><Icons width={20} fillColor="gray" type="globe" className="transform hover:scale-125" /></a>
              )}
            </>
          )}
        </div>
        <a href={`https://www.google.com/maps/dir/?api=1&destination=${selectedPlace.pos.lat},${selectedPlace.pos.lng}`}
           target="_blank"
           className="bg-gray-800 text-white active:bg-gray-600 font-bold tracking-wider uppercase text-xs px-3 py-1.5 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150 flex justify-between items-center gap-4 w-auto hover:bg-black"
           onClick={ (e) => {
             global.trackAnalyticsEvent('link', 'Directions', selectedPlace.name);
           }}
        >
          <Icons width={10} type="mapmarker" fillColor="orange" />
          Directions
        </a>
      </div>
    </div>
  )
}
