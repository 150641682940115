import React from "react";
import Icons from "./Icons";
import SocialLink from "./SocialLink";

export default function Card({ location }) {
  return (
    <section
      className="shadow-md rounded-md overflow-hidden bg-gray-200 text-gray-900 flex flex-col relative"
      style={{
        minHeight: "350px",
        maxHeight: "350px",
      }}
    >
      <header className="py-3 px-5 bg-gray-100">
        <h3 className="text-black text-lg font-semibold mb-1 flex gap-2 items-start">
          <Icons className="flex-shrink-0" width={20} type={location.type} />
          <span className="flex-grow">{location.name}</span>
        </h3>
      </header>

      <div className="p-3 overflow-y-scroll" style={{flexGrow: 2}}>
        <address className="italic flex flex-col mb-4 text-sm text-gray-600">
          {location.address.street}
          <br />
          {location.address.city}, {location.address.state}{" "}
          {location.address.zip}
        </address>

        {location.description && (
          <div className="description mb-6">{location.description}</div>
        )}

        {location.openDate && (
          <div className="openingDate mb-4">
            Opens:
            <br />
            {location.openDate}
          </div>
        )}

        {location.hours && (
          <div className="hours mb-4">
            Hours:
            <br />
            {location.hours}
          </div>
        )}
      </div>

      <footer className="p-3 bg-gray-100 flex justify-between">
        <div className="social flex justify-start gap-2">
          {location.social && (
            <>
              {location.social.facebook && (
                <SocialLink
                  title="View on Facebook"
                  url={location.social.facebook}
                  platform="Facebook"
                  fillColor="#2563EB"
                  iconType="facebookSquare"
                />
              )}
              {location.social.instagram && (
                <SocialLink
                  title="View on Instagram"
                  url={location.social.instagram}
                  platform="Instagram"
                  fillColor="#text-blue-600"
                  iconType="instagram"
                />
              )}
              {location.social.youtube && (
                <SocialLink
                  title="View on YouTube"
                  url={location.social.youtube}
                  platform="YouTube"
                  fillColor="#f00"
                  iconType="youtube"
                />
              )}
              {location.social.website && (
                <SocialLink
                  title="View website"
                  url={location.social.website}
                  platform="Website"
                  fillColor="gray"
                  iconType="globe"
                />
              )}
            </>
          )}
        </div>

        <a
          href={`https://www.google.com/maps/dir/?api=1&destination=${location.pos.lat},${location.pos.lng}`}
          target="_blank"
          className="bg-gray-800 text-white active:bg-gray-600 font-bold tracking-wider uppercase text-xs px-3 py-1.5 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150 flex justify-between items-center gap-4 w-auto hover:bg-black"
          onClick={(e) => {
            global.trackAnalyticsEvent("link", "Directions", location.name);
          }}
        >
          <Icons width={10} type="mapmarker" fillColor="orange" />
          Directions
        </a>
      </footer>
    </section>
  );
}
