import { NavLink } from "react-router-dom";

import azhLogo from "../images/azhaunters-color.png";
import { useEffect } from "react";
import RegisterCTA from "../components/RegisterCTA";
import { useAppContext } from "../lib/contextLib";

const HomeView = () => {
  const { locations } = useAppContext();
  useEffect(() => {
    global.trackAnalyticsPageView(`/`, "Home");
  }, []);

  return (
    <div className="homeView lg:w-3/4 xl:w-2/5 m-auto">
      <div className="md:grid md:grid-cols-2 w-full">
        <div className="col-span-2">
          <h2 className="px-8 text-2xl text-center md:text-left">
            Welcome to the Official AZHaunters' Haunt Listing site!
          </h2>
        </div>

        {locations.length === 0 && (
          <div className="col-span-2">
            <div className="text-center mt-8">
              No haunts have been added yet for this year, please check back!
            </div>
            <div className="mt-8 text-sm bg-gray-400 text-black rounded p-3">
              Note: if you listed your haunt last year, please login and follow
              the instructions to re-list your location.
            </div>
          </div>
        )}

        <div className="p-2 md:p-4 flex flex-col gap-16 md:gap-8">
          {locations.length === 0 && (
            <div className="text-center mt-8">
              No haunts yet, please check back!
            </div>
          )}

          {locations.length > 0 && (
            <div className="text-center mt-8">
              <NavLink
                to="/map"
                className="py-4 px-8 font-bold text-gray-100 hover:text-gray-50 bg-orange-500 hover:bg-orange-600 text-lg rounded w-40"
              >
                View Map
              </NavLink>
            </div>
          )}

          <div className="md:mt-8 flex justify-center">
            <RegisterCTA />
          </div>

          <div className="lg:mt-16">
            <h3 className="border-b border-gray-400 text-xl mb-2">
              About this Site
            </h3>
            <p>
              We wanted to create a site where AZ Haunters' members can list
              their "haunts", and other Haunters and the public can easily
              locate them and enjoy all the skill and passion that went into
              their creation.
            </p>
          </div>
        </div>
        <div className="my-8 flex justify-center content-center">
          <div className="flex justify-center items-start">
            <a
              href="https://www.azhaunters.com?utm_source=azhauntmaps&utm_medium=cpc&utm_campaign=azhauntmaps"
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                global.trackAnalyticsEvent(
                  "link",
                  "External",
                  "AZ Haunters (logo)"
                );
              }}
            >
              <img
                src={azhLogo}
                id="azhlogo"
                className="rounded-full w-64"
                alt="AZ Haunters Logo"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeView;
