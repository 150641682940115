const Footer = () => {
    return (
        <footer id="footer" className="bg-black text-gray-300 flex justify-between justify-items-center items-center px-2">
            <div className="flex-shrink-0 py-3 text-sm">
                <div>&copy; 2024 <a
                    className="text-yellow-600"
                    href="https://www.azhaunters.com?utm_source=azhauntmaps&utm_medium=cpc&utm_campaign=azhauntmaps"
                    onClick={ (e) => {
                        global.trackAnalyticsEvent('link', 'External', 'AZ Haunters (footer link)');
                    }}
                >AZ Haunters</a></div>
                <div>Developed by: <a
                    className="text-green-600"
                    href="https://haunthosting.com/?utm_source=azhauntmaps&utm_medium=cpc&utm_campaign=azhauntmaps"
                    onClick={ (e) => {
                        global.trackAnalyticsEvent('link', 'External', 'HauntHosting (footer link)');
                    }}
                >HauntHosting.com</a></div>
            </div>
        </footer>
    )
}

export default Footer
