import {
  StrictMode,
  createContext,
  Fragment,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  ConfigCatProvider,
  createConsoleLogger,
  LogLevel,
} from "configcat-react";
import { render } from "react-dom";
import {
  BrowserRouter as Router,
  Link,
  NavLink,
  useHistory,
  Route,
  Switch,
} from "react-router-dom";
import Routes from "./Routes";
import Nav from "./components/Nav";
import { Amplify, API, Auth } from "aws-amplify";
import amplify_config from "./amplify_config";
import Footer from "./components/Footer";
import { AppContext, useAppContext } from "./lib/contextLib";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga4";
import { useFeatureFlag } from "configcat-react";

/*
 * Todo: 2022-09-23
 *  1. Finish checking the "getMyHaunt" functionality. I need to re-add the getAllHaunts piece, and
 *     check for an existing listing, otherwise go fetch their haunt
 *  2. Add LocalStorage functionality to cache the results.
 *  3. Improve the loading experience so pages don't "flash" around so much
 *
 *  Fixme: Additional notes:
 *   - When the haunt is re-activated, how do I want to handle the list?  Re-fetch, just update it or what?
 *
 */
1;
ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS_G4}`);

global.trackAnalyticsPageView = (path, title) => {
  // console.log('trackAnalyticsPageView', pageView)
  ReactGA.send({ hitType: "pageview", page: { path }, title: { title } });
};

/* Not being used?
global.trackAnalyticsModalView = (path) => {
  // console.log('trackAnalyticsModalView', path)
  ReactGA.modalview(path);
};
*/

global.trackAnalyticsEvent = (category, action, label) => {
  // console.log('trackAnalyticsEvent', category, action, label)
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

// TODO: wire up event tracking, when someone clicks various items on site, like specific location, directions, etc
//   - Hook into filter form to capture filter "terms"

const logger = createConsoleLogger(LogLevel.Info); // Set the log level to INFO to track how your feature flags were evaluated. When moving to production, you can remove this line to avoid too detailed logging.

const App = () => {
  const history = useHistory();

  const underConstruction = useFeatureFlag("isOffSeason", false).value;

  const { value: isAwesomeFeatureEnabled, loading } = useFeatureFlag(
    "isMyFirstFeatureEnabled",
    false
  );

  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [locations, setLocations] = useState([]);

  const [userInfo, setUserInfo] = useState({});
  const [reloadLocations, setReloadLocations] = useState(false);
  const [hasHaunt, setHasHaunt] = useState(false);
  const [myHaunt, setMyHaunt] = useState({});
  // const [isHauntActive, setIsHauntActive] = useState(false);
  // const [homeCoords, setHomeCoords] = useState({ lat: 0, lng: 0 });
  // const [lat, setLat] = useState(0);
  // const [lng, setLng] = useState(0);
  const [userPosition, setUserPosition] = useState(null);

  // geoLocation useEffect Hook
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (position.coords.latitude && position.coords.longitude) {
            setUserPosition(position.coords);
          }
        },
        (error) => {
          /* empty */
        }
      );
    } else {
      /* empty */
    }
  }, []);

  useEffect(async () => {
    if (reloadLocations || locations.length === 0) {
      await onLoad();
      setReloadLocations(false);
    }
  }, [reloadLocations]);

  /*
   This should fire when isAuthenticated is changed and does a search for the
   logged in account's haunt
   */
  useEffect(async () => {
    // console.log('isAuthenticated useEffect');
    if (isAuthenticated) {
      await checkForOwnHaunt();
    }
  }, [isAuthenticated, locations]);

  /*
  Set the `setMyHaunt` state, why am I doing it here if it is
  doing it below in checkForOwnHaunt 🤔
  */
  useEffect(() => {
    if (hasHaunt === false) {
      setMyHaunt(false);
    }
  }, [hasHaunt]);

  const loadLocation = async () => {
    return API.get("locations", `/locations/${userInfo.id}`, null);
  };

  /*
      Todo: refactor....
  */
  const checkForOwnHaunt = async () => {
    if (isAuthenticated) {
      // console.log('checkForOwnHaunt', userInfo.id);
      // Let's grab all the haunts, and then check to see if there is a match
      /** temporarily commented out.  need to work on implementing a GSI1 call
       if (locations.length > 0 && !hasHaunt) {
            let obj;
            obj = locations.find(o => o.userId === userInfo.id);

            if (!obj) {
                // Fixme: We need to make another call to the DB using GSI1 to see if there is a result or not.
                setMyHaunt(false);
                setHasHaunt(false);
                // getHauntByHaunterId()
            }
            if (obj) {
                setMyHaunt(obj);
                setHasHaunt(true);
            }
        }
       */

      await getMyLocation();
    }
  };

  const getMyLocation = async () => {
    // console.log('foo', encodeURI(userInfo.id) );
    const myInit = {
      // OPTIONAL
      headers: {
        Accept: "application/json",
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    };

    try {
      await API.get("locations", `/locations/profile`, myInit)
        .then((response) => {
          // console.log("response", response.status);
          // Todo: Check the status codes, 204 means no data found, so set the hasHaunt to false

          // if (response.status === 204) {
          //   console.log("‼️ NO haunt found for this user");
          // }

          if (response.status === 200 && response.data) {
            // console.log("✅ haunt found for this user", response.data);
            // return response.data;
            setMyHaunt(response.data);
            setHasHaunt(true);
          }
        })
        .catch((errors) => {
          // console.log(errors);
          setHasHaunt(false);
          return false;
        });
    } catch (err) {
      /* empty */
      // console.log(err);
    }

    // const response = await API.get("locations", `/locations/profile`);
    // const response = await API.put('myapi', 'endpoint')

    // console.log(response.getStatusCode());

    // if(response.getStatusCode() === 404){
    //     console.log("not found!")
    // }
    // console.log('1 - myHaunt', myHaunt);

    // return response;
  };

  /*
      const getHauntByHaunterId = async () => {
          // console.log('getHauntByHaunterId');
          // console.log('userInfo', userInfo.id);

          return await getMyLocation();

          // console.log('2 - myHaunt', myHaunt);
          //
          // return false;
      }
  */

  const loadUserDetails = async () => {
    const uInfo = await Auth.currentUserInfo();

    // Retrieve the user's email
    const authUserInfo = await Auth.currentAuthenticatedUser();

    // console.log('authUserInfo', authUserInfo);
    setUserInfo((prevState) => ({
      ...prevState,
      id: uInfo.id,
      email: authUserInfo.attributes.email,
    }));
  };

  const loadListings = async () => {
    // console.log('loadListings, should this be a useEffect?');
    setIsLoading(true);

    const locs = await loadAllLocations();

    // Sort the list alphabetically, ignoring case
    locs.sort((a, b) => {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });

    setLocations(locs);
  };

  const onLoad = async () => {
    // console.log('onLoad');
    try {
      await loadListings();

      const currentSession = await Auth.currentSession();

      if (currentSession && !userInfo.id) {
        await loadUserDetails();
        userHasAuthenticated(true);
      }

      setIsLoading(false);
    } catch (e) {
      if (e !== "No current user") {
        alert(e);
      }
    }
    setIsAuthenticating(false);
  };

  const loadAllLocations = () => {
    return API.get("locations", "/locations", null);
  };

  return (
    !isAuthenticating && (
      <div id="wrapper" className="flex flex-col h-screen">
        {/*
                {isLoading && ( <div>Loading...</div>)}
                {!isLoading && (
*/}
        <AppContext.Provider
          value={{
            isAuthenticated,
            userHasAuthenticated,
            userInfo,
            setUserInfo,
            isLoading,
            setIsLoading,
            locations,
            myHaunt,
            setMyHaunt,
            hasHaunt,
            setHasHaunt,
            setReloadLocations,
            underConstruction,
            userPosition,
          }}
        >
          <Fragment>
            <Nav />
            <main className="flex-auto px-4 md:px-8">
              <Routes />
            </main>
            <Footer />
          </Fragment>
        </AppContext.Provider>
        {/*
                )}
*/}
      </div>
    )
  );
};

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: amplify_config.cognito.REGION,
    userPoolId: amplify_config.cognito.USER_POOL_ID,
    identityPoolId: amplify_config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: amplify_config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: amplify_config.s3.REGION,
    bucket: amplify_config.s3.BUCKET,
    identityPoolId: amplify_config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: "locations",
        endpoint: amplify_config.apiGateway.URL,
        region: amplify_config.apiGateway.REGION,
      },
    ],
  },
});

render(
  <StrictMode>
    <ConfigCatProvider
      sdkKey={process.env.CONFIG_CAT_SDK_KEY}
      options={{ logger }}
    >
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Router>
        <App />
      </Router>
    </ConfigCatProvider>
  </StrictMode>,
  document.getElementById("root")
);
