import { Route, Switch } from "react-router-dom";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

import HomeView from "./views/Home";
import NotFound from "./views/NotFound";
import LoginView from "./views/Login";
import ResetPasswordView from "./views/ResetPassword";
import Signup from "./views/Signup";
import AddLocation from "./views/AddLocation";
import EditLocation from "./views/EditLocation";
import Location from "./views/Location";
import Settings from "./views/Settings";
import Profile from "./views/Profile";
import ListView from "./views/Listing";
import MapView from "./views/Map";
import HelpView from "./views/Help";
import ConfirmationCodeView from "./views/SubmitConfirmationCode";
import UnderConstructionView from "./views/construction";
import { useAppContext } from "./lib/contextLib";

const Routes = () => {
  const { underConstruction } = useAppContext();

  return (
    <Switch>
      {/* Routes that are ONLY available to AUTHENTICATED users */}
      <AuthenticatedRoute exact path="/locations/add">
        <AddLocation />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/locations/edit/:id">
        <EditLocation />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/settings">
        <Settings />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/profile">
        <Profile />
      </AuthenticatedRoute>

      {/* Routes that are ONLY available to UNAUTHENTICATED users */}
      <UnauthenticatedRoute exact path="/signup">
        <Signup />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/login/reset">
        <ResetPasswordView />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/login">
        <LoginView />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/login/confirm">
        <ConfirmationCodeView />
      </UnauthenticatedRoute>

      {/* Routes that are available to ALL users */}
      <Route exact path="/locations/:id">
        <Location />
      </Route>
      <Route exact path="/listing">
        <ListView />
      </Route>
      <Route exact path="/help">
        <HelpView />
      </Route>
      <Route exact path="/map">
        <MapView />
      </Route>
      <Route exact path="/">
        <>
          {underConstruction && <UnderConstructionView />}

          {!underConstruction && <HomeView />}
        </>
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
};

export default Routes;
