import React, { Fragment, useEffect, useState } from "react";
import { API } from "aws-amplify";
import { NavLink, useHistory } from "react-router-dom";
import { onError } from "../lib/errorLib";
import { useAppContext } from "../lib/contextLib";
import Icons from "../components/Icons";
import LoaderButton from "../components/LoaderButton";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";

export default function Profile() {
  const history = useHistory();
  const { hasHaunt, setHasHaunt, myHaunt, setMyHaunt, setReloadLocations } =
    useAppContext();

  useEffect(() => {
    if (myHaunt) {
      setIsLoading(false);
    }
  }, [myHaunt]);

  // Todo: make isLoading better.  Don't display anything until it is loaded.
  //   Where does the loading happen?  App.js?
  const [isLoading, setIsLoading] = useState(false);

  const [isEditing, setIsEditing] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);
  const [modalOpen, setModalState] = useState(true);

  const handleModalClose = () => {
    // console.log("handleModalClose");
    modalOpen ? setModalState(false) : null;
  };

  const updateMyHaunt = (data) => {
    //console.log("update myHaunt", data);
    setMyHaunt(data);
  };

  function deleteLocation() {
    return API.del("locations", `/locations/${myHaunt.SK}`, null);
  }

  const handleDelete = async (event) => {
    event.preventDefault();

    //console.log("handleDelete");

    // TODO: convert to modal dialog
    const confirmed = window.confirm(
      "Are you sure you want to delete this location? \n\nNote, this is irreversible and you will have to re-add your haunt again"
    );

    if (!confirmed) {
      // console.log("confirm handleDelete...");
      return;
    }

    setIsDeleting(true);

    try {
      // TODO: This only deletes it from the DB, need to add functionality to remove attached files
      const response = await deleteLocation();

      if (response.status) {
        setHasHaunt(false);
        setMyHaunt({});

        setReloadLocations(true);
        setIsLoading(false);

        history.push("/");
      }

      // console.log("delete response");
      // console.dir(response);

      setReloadLocations(false);
      setIsLoading(false);

      toast.success("Successfully deleted your location 🪦");

      // history.push("/");
    } catch (e) {
      onError(e);
      setIsDeleting(false);
    }
  };

  const handleEdit = () => {
    //console.log("handleEdit, what do I need to do. 🤔");
    history.push(`/locations/edit/${myHaunt.SK}`);
    // setIsEditing(true);
  };

  const makeApiCall = async (api) => {
    const response = await API.put(
      "locations",
      `/locations/${api}/${myHaunt.SK}`,
      { headers: { Accept: "application/json" }, response: true }
    );

    return response.data;
  };

  const handleActivate = async () => {
    setIsLoading(true);
    // console.log("make reactivate API call");

    const data = await makeApiCall("activate");

    updateMyHaunt(data);

    handleModalClose();
    setIsLoading(false);

    toast.success("Successfully Activated your location 🎃");
  };

  const handleDeactivate = async () => {
    // console.log("make deactivate API call");
    setIsLoading(true);
    const data = await makeApiCall("deactivate");

    updateMyHaunt(data);

    handleModalClose();
    setIsLoading(false);

    toast.success("Successfully Deactivated your location");
  };

  /* Modal */
  // TODO: extract this back to its own component.
  const MyDialog = () => {
    return (
      <Transition.Root show={modalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => handleModalClose()}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-80 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-start justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon
                          className="h-6 w-6 text-red-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-xl font-medium leading-6 text-gray-900"
                        >
                          Welcome Back!
                        </Dialog.Title>
                        <div className="mt-4">
                          <p className="text-sm text-gray-500 mb-2">
                            In order to keep the Haunt Map listings up to date,
                            we are asking each haunt owner to{" "}
                            <span className="font-bold">"reactivate"</span>{" "}
                            their haunt.
                          </p>
                          <p className="text-sm text-gray-500 mb-2">
                            If your haunt will be open this year (2023), simply
                            click the "Reactivate" button and you will
                            automatically be re-added to the site.
                          </p>
                          <p className="text-sm text-gray-500">
                            If you are not ready to reactivate your haunt, just
                            click the "No" button. You will be able to
                            reactivate it at another time.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <LoaderButton
                      isLoading={isLoading}
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={handleActivate}
                    >
                      Reactivate
                    </LoaderButton>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => handleModalClose()}
                    >
                      No
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const displayEditForm = () => {
    return (
      <>
        <div className="w-full max-w-xl mx-auto mb-4">
          <div className="shadow-md rounded-md overflow-hidden bg-white text-gray-900"></div>
        </div>
      </>
    );
  };

  /* TODO: Add, and style this section */
  const displayAddHaunt = () => {
    return (
      <div className="w-full max-w-xl mx-auto mb-4">
        <div className="shadow-md rounded-md overflow-hidden bg-white text-gray-900 pb-2">
          <div className="p-3">
            <h3 className="text-black text-2xl font-semibold mb-1 flex gap-2 items-center">
              Welcome to AZ Haunt Map!
            </h3>
          </div>
          <div className="p-3 text-center">
            <p className="mb-12">
              It appears that you do not have a haunt listed on the site. Please
              click the button below if you would like to add your haunt now.
            </p>
            <NavLink
              to="/locations/add"
              className="w-full md:w-auto text-sm bg-yellow-600 px-8 py-2 text-white rounded font-medium text-lg hover:bg-gray-500"
            >
              Add your Haunt now!
            </NavLink>
          </div>
        </div>
      </div>
    );
  };

  const displayLocationCard = () => {
    return (
      <>
        <div className="w-full max-w-xl mx-auto mb-4">
          <div className="p-3">
            <h3 className="text-white text-2xl font-semibold mb-10 ">
              Welcome back {myHaunt.contact.firstName}
            </h3>
          </div>
          <div className="">
            <p className="text-white text-xl font-semibold mb-5 flex gap-2 items-center">
              Your Haunt information:
            </p>

            <div className="shadow-md rounded-md overflow-hidden bg-white text-gray-900">
              <div className="p-3">
                <h3 className="text-black text-2xl font-semibold mb-1 flex gap-2 items-center">
                  <Icons width={20} type="haunt" /> {myHaunt.name}
                </h3>

                <address className="italic flex flex-col mb-4 text-sm text-gray-600">
                  {myHaunt.address.street}
                  <br />
                  {myHaunt.address.city}, {myHaunt.address.state}{" "}
                  {myHaunt.address.zip}
                </address>

                {myHaunt.description && (
                  <div className="description mb-6">{myHaunt.description}</div>
                )}

                {myHaunt.openDate && (
                  <div className="openingDate mb-4">
                    Opening Day:
                    <br />
                    {myHaunt.openDate}
                  </div>
                )}

                {myHaunt.hours && (
                  <div className="hours mb-4">
                    Hours:
                    <br />
                    {myHaunt.hours}
                  </div>
                )}
              </div>

              <div className="p-3 bg-gray-100 flex justify-between">
                <LoaderButton
                  type={"button"}
                  className="text-red-500 hover:text-red-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex justify-between w-auto gap-4 items-center"
                  onClick={handleEdit}
                  isLoading={isEditing}
                >
                  Edit
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                    />
                  </svg>
                </LoaderButton>

                <LoaderButton
                  type={"button"}
                  className="text-red-500 hover:text-red-700 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex justify-between w-auto gap-4 items-center"
                  onClick={handleDelete}
                  isLoading={isDeleting}
                >
                  Delete
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                  </svg>
                </LoaderButton>

                {!myHaunt.isActive && (
                  <LoaderButton
                    isLoading={isLoading}
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleActivate}
                  >
                    Reactivate
                  </LoaderButton>
                )}

                {/* TODO: Add a "deactivate" modal explaining what this functionality does. */}
                {myHaunt.isActive && (
                  <LoaderButton
                    isLoading={isLoading}
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleDeactivate}
                  >
                    Deactivate
                  </LoaderButton>
                )}
              </div>
              {myHaunt.isActive && (
                <div className="px-4 py-2 text-red-900 ">
                  <p>
                    <strong>Note:</strong> Deactivating your haunt does not
                    delete it, only removes it from the listings. You can
                    "reactivate" it anytime.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="profile">
      {isLoading && !hasHaunt && <div>Loading...</div>}

      {/* Add Haunt section */}
      {!hasHaunt && !isLoading && displayAddHaunt()}

      {/*
      {hasHaunt && !myHaunt.isActive && <ReactivateModal />}
      */}

      {/*
      TODO: Finish wiring up the Reactivate Modal, how cna I detect if the modal is open?
        I want to only show the modal, but if they close it, then display their haunt.
       */}
      {
        myHaunt && !myHaunt.isActive && MyDialog()
        /*
        <ReactivateModal
          activate={handleReactivate}
          deactivate={handleDeactivate}
          handleClose={handleModalClose}
          open={modalOpen}
          // showModal={setModalState}
        />
        */
      }

      {hasHaunt && !isEditing && displayLocationCard()}

      {hasHaunt && isEditing && displayEditForm()}
    </div>
  );
}
