import MapMarker from './MapMarker'
/*
  Docs: https://react-google-maps-api-docs.netlify.com/
*/
import {GoogleMap, InfoWindow, useLoadScript} from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { infoWindowContent } from "./organisms/infoWindowContent";

const GoogleMapContainer = props => {
    // Grab the data out of the props for display on the map
    const {mapLocations, mapData} = props

/*
TODO: 2023-10-10:
  Look at replacing InfoWindow with OverlayView
*/

    // The things we need to track in state
    const [mapRef, setMapRef] = useState(null);
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [markerMap, setMarkerMap] = useState({});
    const [center, setCenter] = useState(mapData.center);
    const [zoom, setZoom] = useState(mapData.zoom);
    const [infoOpen, setInfoOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);

    useEffect( () => {
        setCenter(mapData.center);
    }, [mapData]);

    // Load the Google Maps scripts
    const {isLoaded} = useLoadScript({
        googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`
    });

    const loadHandler = map => {
        // Why am I doing this?  I don't seem to be referencing the mapRef anywhere
        // Store a reference to the Google map instance in state
        setMapRef(map);
    };

    // We have to create a mapping of our places to actual Marker objects
    const markerLoadHandler = (marker, place) => {
        return setMarkerMap(prevState => {
            return {...prevState, [place.SK]: marker};
        });
    };

    const markerClickHandler = (event, place) => {
        // Remember which place was clicked
        setSelectedPlace(place);


        global.trackAnalyticsEvent("map", "Marker Clicked", `${place.name} (${place.SK})`);

        // Required so clicking a 2nd marker works as expected
        if (infoOpen) {
            setInfoOpen(false);
        }

        setInfoOpen(true);

        // If you want to zoom in a little on marker click
        if (zoom < 13) {
            setZoom(13);
        }

        // if you want to center the selected Marker
        setCenter(place.pos)
    };

    const infoWindowClose = () => {
        setZoom(props.mapData.zoom);
        setInfoOpen(false);
    }

    const markers = mapLocations.map(place => (
        <MapMarker
          key={place.SK}
          place={place}
          loadHandler={
            marker => markerLoadHandler(marker, place)
          }
          clickHandler={
            event => markerClickHandler(event, place)
          }
          mouseOutHandler={
            event => infoWindowClose()
          }
        />
    ))

    /**
     * This hides pretty much everything on the map except for the Haunt locations
     */
    const styles = {
        default: [],
        hide: [
            {
                // featureType: "poi.business",
                featureType: "poi",
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "transit",
                elementType: "labels.icon",
                stylers: [{ visibility: "off" }],
            },
        ],
    };


    const mapOptions = {
        keyboardShortcuts: false, // disable keyboard shortcuts
        draggable: true, // make map draggable
        scaleControl: true, // allow scale control
        scrollwheel: true, // allow scroll wheel
        streetViewControl: false,
        controlSize: '20',
        zoomControlOptions: {position: 9},
        styles: styles["hide"]
    }

    const renderMap = () => {
        return (
            <>
                <GoogleMap
                    // Do stuff on map initial load
                    onLoad={loadHandler}
                    center={center}
                    zoom={zoom}
                    mapContainerStyle={{
                        height: "100%",
                        width: "100%"
                    }}
                    options={mapOptions}
                >
                    {markers}

                    {infoOpen && selectedPlace !== null && (
                        <InfoWindow
                            anchor={markerMap[selectedPlace.SK]}
                            onCloseClick={infoWindowClose}
                            options= {
                                {
                                    minWidth : 275
                                }
                            }
                        >
                            {infoWindowContent(selectedPlace)}
                        </InfoWindow>
                    )}
                </GoogleMap>
            </>
        );
    };
    if (isLoading) {
        return (
            <div className="ui segment">
                Loading...
                <button onClick={() => setLoading(false)}>Delete</button>
            </div>
        )
    }

    return isLoaded ? renderMap() : null;
}

export default GoogleMapContainer
