import Icons from "./Icons";
import React from "react";

const SocialLink = ({
  title,
  url,
  platform,
  fillColor,
  iconType,
  ...props
}) => {
  console.log(
    `title: ${title}, url: ${url}, platform: ${platform}, fillColor: ${fillColor}, iconType: ${iconType}`
  );
  return (
    <a
      title={title}
      target="_blank"
      href={url}
      onClick={(e) => {
        global.trackAnalyticsEvent("Social", { platform }, location.name);
      }}
    >
      <Icons
        width={24}
        fillColor={fillColor}
        type={iconType}
        className="transform hover:scale-125"
      />
    </a>
  );
};

export default SocialLink;
