const amplify_config = {
    MAX_ATTACHMENT_SIZE: 5000000,
    s3: {
        REGION: `${process.env.AWS_REGION}`,
        BUCKET: "test-haunt-map-v2",
    },
    apiGateway: {
        REGION: `${process.env.AWS_REGION}`,
        URL: `${process.env.AWS_API_URL}`,
    },
    cognito: {
        REGION: `${process.env.AWS_REGION}`,
        USER_POOL_ID: `${process.env.AWS_USER_POOL_ID}`,
        APP_CLIENT_ID: `${process.env.AWS_APP_CLIENT_ID}`,
        IDENTITY_POOL_ID: `${process.env.AWS_IDENTITY_POOL_ID}`,
    },
};

export default amplify_config;
